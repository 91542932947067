'use strict';

class feedbackMessages {

	/* @ngInject */
	constructor($rootScope) {

		this.$rootScope = $rootScope;
	}

	cleanMessages(scope) {

		scope && scope.$emit('mainCleanMessages');
		!scope && this.$rootScope.$broadcast('mainCleanMessages');
	}

	showErrorMessage(scope, message) {

		scope && scope.$emit('mainErrorMessage', message);
		!scope && this.$rootScope.$broadcast('mainErrorMessage', message);
	}

	showSuccessMessage(scope, message) {

		scope && scope.$emit('mainSuccessMessage', message);
		!scope && this.$rootScope.$broadcast('mainSuccessMessage', message);
	}

	setWarningMessage(scope, message) {
		scope && scope.$emit('mainSuccessMessage', message);
		!scope && this.$rootScope.$broadcast('mainSuccessMessage', message);
	}
}

module.exports = feedbackMessages;
