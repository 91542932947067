'use strict';

class paginationHelper {

	// @ngInject
	constructor() {

		this.setPagesPristine();
	}

	setPagesPristine() {

		this.totalOfRecords = 0;
		this.actualPage = 1;
		this.totalOfPages = 1;
		this.limitOfRecords = 10;
	}

	reset() {

		this.setPagesPristine();
	}

	hasPrevious() {

		return (this.actualPage != 1 && this.actualPage <= this.totalOfPages);
	}

	hasNext() {

		return (this.actualPage < this.totalOfPages && this.totalOfPages > 1);
	}

	doPrevious(callback, scope) {

		this.actualPage--;

		if (this.isPageValid()) {

			callback.call(scope);
		} else {

			this.actualPage++;
		}
	}

	doNext(callback, scope) {

		this.actualPage++;

		if (this.isPageValid()) {
			callback.call(scope);
		} else {

			this.actualPage--;
		}
	}

	isPageValid() {

		return (this.actualPage > 0 && this.actualPage <= this.totalOfPages)
	}

	setPaginationParams() {

		this.actualPage = 1;
		this.totalOfPages = (this.totalOfRecords > this.limitOfRecords) ? parseInt(Math.ceil(this.totalOfRecords / this.limitOfRecords)) : 1;
	}
}

module.exports = paginationHelper;
