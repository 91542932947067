'use strict';

/* @ngInject */
function routesConfiguration($stateProvider, sfConfig) {
	$stateProvider.state('simulador', {
		url: '/simulador',
		views: {
			content: {
				templateUrl: `${sfConfig.resource}/views/simulator/simulator.html`,
				controller: 'simulatorController',
				controllerAs: 'simulator'
			}
		}
	});

	$stateProvider.state('simulator-dupla-garantia', {
		url: '/simulador-dupla-garantia',
		views: {
			content: {
				templateUrl: `${sfConfig.resource}/views/simulator/simulator-dupla-garantia.html`,
				controller: 'simulatorDuplaGarantiaController',
				controllerAs: 'simulatorDuplaGarantia'
			}
		}
	});

	$stateProvider.state('simulator-garantia-aluguel', {
		url: '/simulator-garantia-aluguel',
		views: {
			content: {
				templateUrl: `${sfConfig.resource}/views/simulator/simulator-garantia-aluguel.html`,
				controller: 'simulatorGarantiaAluguelController',
				controllerAs: 'simuladorGarantiaAluguel'
			}
		}
	});

	$stateProvider.state('simulator-prestamista-avulso', {
		url: '/simulator-prestamista-avulso',
		views: {
			content: {
				templateUrl: `${sfConfig.resource}/views/simulator/simulator-prestamista-avulso.html`,
				controller: 'simulatorPrestamistaAvulsoController',
				controllerAs: 'simuladorPrestamistaAvulso'
			}
		}
	});
}

module.exports = routesConfiguration;
