'use strict';

const filtersModule = angular.module('cap.core.filtersModule', [])

	/* ------------------------------------------------
		CAP-3498 - Alterar a nomenclatura do status
			'Em andamento' para 'Em rascunho'
	------------------------------------------------ */
	.filter('statusFilter', () => (status) => {
		let retVal = (!status) ? "" : status;
		if (retVal.toLowerCase() === 'em andamento') retVal = 'Em Elaboração';
		return retVal;
	})

	/* ------------------------------------------------
		CAP-3351 - Melhoria descrição tipo de
		solicitação "Liberação da Caução"
	------------------------------------------------ */
	/* ------------------------------------------------
		CAP-4010 - Marco Regulatório
	------------------------------------------------ */
	.filter('caseTypeFilter', () => (caseType) => {
		let retVal = (!caseType) ? "" : caseType;
		if (retVal.toLowerCase() === 'liberação da caução') retVal = 'Liberação da Garantia sem pagamento';
		if (retVal.toLowerCase() === 'inclusão da caução') retVal = 'Inclusão da garantia';
		return retVal;
	});

module.exports = filtersModule;
