'use strict';

class simulatorOptionalCharges {

	setDefaultPristine(formSimulate) {
		// console.log('setDefaultPristine');

		this.field = {

			nome: null,
			valor: null,
			multiplicador: null

		};

		formSimulate.lstOptionalCharges.push({

			name: 'Condomínio',
			value: 0
		});

		formSimulate.lstOptionalCharges.push({

			name: 'IPTU',
			value: 0
		});
	}

	resetField(optionalCharge) {

		optionalCharge.name = null;
		optionalCharge.value = null;
	}

	isValid(form) {

		this.isOptionalChargeEmpty(form);

		return form.optionalChargeName.$valid && form.optionalChargeValue.$valid;
	}

	isOptionalChargeEmpty(form) {

		let isValid = true;

		if (!form.optionalChargeName.$viewValue) {

			form.optionalChargeName.$setTouched();
			form.optionalChargeName.$setValidity('minlength', false);
			isValid = false;
		}

		if (!form.optionalChargeValue.$viewValue) {

			form.optionalChargeValue.$setTouched();
			form.optionalChargeValue.$setValidity('min', false);
			isValid = false;
		}

		return isValid;
	}

	forceFieldsValidity(form) {

		form.optionalChargeName.$setValidity('minlength', true);
		form.optionalChargeValue.$setValidity('min', true);
	}

	add(form, item, formSimulate) {

		if (this.isValid(form)) {

			// Só permite 16 items (15 com dados e 1 em branco)
			if (formSimulate.lstOptionalCharges.length < 15) {

				formSimulate.lstOptionalCharges.push(angular.copy(item));
			} else {

				//console.log('*** O limite de 15 registros foi ultrapassado');
			}

			this.resetField(item);
		}
	}

	maxiumSizeReached(formSimulate) {

		return (formSimulate.lstOptionalCharges.length >= 15);
	}

	remove(itemIndex, formSimulate) {

		formSimulate.lstOptionalCharges.splice(itemIndex, 1);
	}

	isDefault(item) {
		// console.log('isDefault', item);

		return item.name === 'Condomínio' || item.name === 'IPTU';
	}
}

module.exports = simulatorOptionalCharges;
