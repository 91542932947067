'use strict';

class breadcrumbHelper {

	// @ngInject
	constructor() {
		this.setNavItemPristine();
	}
	setNavItemPristine() {
		this.menuItens = [];
	}
	reset() {

		this.setNavItemPristine();
	}
	addMenuItem(paMenuItemText, paMenuItemUrl, paRenderAsLink, paSelected) {
		const vaMenuItem = {
			menuItemText: paMenuItemText,
			menuItemUrl: paMenuItemUrl,
			renderAsLink: paRenderAsLink,
			selected: paSelected
		};
		this.menuItens.push(vaMenuItem);
	}
	getMenuItens() {
		return this.menuItens;
	}

}

module.exports = breadcrumbHelper;
