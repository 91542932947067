'use strict';

/* @ngInject */
function routesConfiguration($stateProvider, sfConfig) {

	$stateProvider.state('changePassword', {

		url: '/alterar-senha',
		views: {

			'content': {

				templateUrl: `${sfConfig.resource}/views/change-password/change-password.html`,
				controller: 'changePasswordController',
				controllerAs: 'changePassword'
			}
		}
	});
};

module.exports = routesConfiguration;
