'use strict';

const limitPhoneLength = 10;
const limitCelLength = 11;
const dateLength = 10;
// const rgLength = 15;
const cepLength = 9;
const minimumAge = 18;

class validationFormFields {

	/* @ngInject */
	constructor() {
		this.teste = '';
	}

	isValidCpfCnpj(cpfCnpj) {
		if (this.isValidCpf(cpfCnpj)) {
			return true;
		} else {
			return this.isValidCnpj(cpfCnpj);
		}
	}

	isValidCnpj(cnpj) {

		if (!cnpj) {
			return false;
		}

		cnpj = cnpj.replace(/[^\d]+/g, '');

		if (cnpj == '') {
			return false;
		}

		if (cnpj.length != 14) {
			return false;
		}

		// Elimina CNPJs invalidos conhecidos
		if (cnpj == "00000000000000" ||
			cnpj == "11111111111111" ||
			cnpj == "22222222222222" ||
			cnpj == "33333333333333" ||
			cnpj == "44444444444444" ||
			cnpj == "55555555555555" ||
			cnpj == "66666666666666" ||
			cnpj == "77777777777777" ||
			cnpj == "88888888888888" ||
			cnpj == "99999999999999") {

			return false;
		}

		let tamanho = cnpj.length - 2,
			numeros = cnpj.substring(0, tamanho),
			soma = 0,
			pos = tamanho - 7;

		const digitos = cnpj.substring(tamanho);

		for (let i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2)
				pos = 9;
		}

		resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

		if (resultado != digitos.charAt(0))
			return false;

		tamanho = tamanho + 1;
		numeros = cnpj.substring(0, tamanho);
		soma = 0;
		pos = tamanho - 7;

		for (let i = tamanho; i >= 1; i--) {
			soma += numeros.charAt(tamanho - i) * pos--;
			if (pos < 2)
				pos = 9;
		}

		let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

		if (resultado != digitos.charAt(1)) {
			return false;
		}

		return true;
	}

	isValidCpf(cpf) {

		if (!cpf) {
			return false;
		}

		cpf = cpf.replace(/\D/g, '');

		if (cpf.length != 11 || cpf.replace(eval(`/${cpf.charAt(1)}/g`), '') == '') {
			return false;

		} else {
			for (let n = 9; n < 11; n++) {
				let d = 0,
					c = 0;

				for (c = 0; c < n; c++) {
					d += cpf.charAt(c) * ((n + 1) - c)
				}

				d = ((10 * d) % 11) % 10;

				if (cpf.charAt(c) != d) {
					return false;
				}
			}

			return true;
		}
	}

	isValidEmail(email) {
		//const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const re = /^[a-zA-Z0-9][a-zA-Z0-9\._-]+@([a-zA-Z0-9\._-]+\.)[a-zA-Z-0-9]{2,3}$/;
		return re.test(email);
	}

	hasLastName(name) {

		if (!name) { return false; }

		if (name.split(' ').length > 1) {
			return true;
		}

		return false;
	}

	hasInvalidSequence(str) {
		str = str.replace(/\s+/g, "_");
		return /(\S)(\1{2,})/g.test(str);
	}

	hasSequencedSpace(str) {
		return /(\s)(\1{1,})/g.test(str);
	}

	isValidName(name) {

		return this.hasLastName(name) && !this.hasInvalidSequence(name) && !this.hasSequencedSpace(name) && !this.hasBadExpression(name);
	}

	isSingleValidName(name) {

		return !this.hasInvalidSequence(name) && !this.hasSequencedSpace(name);
	}

	hasBadExpression(name) {

		const nameArray = name.split(' ');

		for (let i = 0; i < nameArray.length; i++) {

			const nameItem = nameArray[i].toUpperCase();

			if (_badExpressions.indexOf(nameItem) !== -1) {

				return true;
			}
		}

		return false;
	}

	validateSingleName(name) {
		// console.log('validateSingleName', name);

		if (!name.$viewValue) {

			name.$setValidity('name', true);
			return;
		}

		const isSingleValidName = this.isSingleValidName(name.$viewValue);

		name.$setValidity('name', isSingleValidName);
	}

	validateSingleNameWithBadExpression(name) {
		// console.log('validateSingleName', name);

		if (!name.$viewValue) {

			name.$setValidity('name', true);
			return;
		}

		const isSingleValidName = this.isSingleValidName(name.$viewValue) && !this.hasBadExpression(name.$viewValue);

		name.$setValidity('name', isSingleValidName);
	}

	validateName(name) {
		// console.log('validateName', name);

		if (!name.$viewValue) {

			name.$setValidity('name', true);
			return;
		}

		const isValidName = this.isValidName(name.$viewValue);

		name.$setValidity('name', isValidName);
	}

	isNumber(str) {
		str = str.replace(/[^\w\s]/gi, '');
		return !isNaN(str);
	}

	isValidCep(cep) {

		cep = cep.split('-').join('');
		// console.log('isValidCep', cep, this.isNumber(cep), cep.length === 8);

		return this.isNumber(cep) && cep.length === 8;
	}

	isValidAddress(address) {
		// return address.length < 5 ? false : true;
		return address.length < 1 ? false : true;
	}

	isValidCity(city) {
		return city.length < 3 ? false : true;
	}

	isValidDistrict(district) {
		return true;
	}

	isValidPhoneNumber(phone) {
		phone = phone.replace(/[^\w\s]/gi, '');

		if (!isNaN(phone) && (phone.length === limitPhoneLength || phone.length === limitCelLength)) {
			return true;
		}

		return false;
	}

	isValidDate(str) {
		return Date.parse(str) ? true : false;
	}

	parseValidDate(strDate) {
		//format 99/99/9999
		const dateParts = strDate.split("/"),
			resultDate = new Date(dateParts[2], (dateParts[1] - 1), dateParts[0]);

		if (Object.prototype.toString.call(resultDate) === "[object Date]" && !isNaN(resultDate.getTime())) {
			return resultDate;
		}
		return;
	}

	isBeforeToday(date) {
		const today = new Date();
		return today > date ? true : false;
	}

	userHasMinimumAge(birthDate) {
		return this.calculateAge(birthDate) < 18 ? false : true;
	}

	calculateAge(birthDate) {
		const todayDate = new Date();
		let age = todayDate.getFullYear() - birthDate.getFullYear();

		if (todayDate.getMonth() < birthDate.getMonth()) {
			age--;
		}

		if (birthDate.getMonth() === todayDate.getMonth() && todayDate.getDate() < birthDate.getDate()) {
			age--;
		}

		return age;
	}

	isValidRg(strRg) {
		return this.isNumber(strRg);
	}

	fieldHasError(form, fieldName) {
		// console.log('fieldHasError', form, fieldName);

		return (form.$submitted || form[fieldName].$touched) && form[fieldName].$invalid;
	}

	resetViewForm(form) {

		form.$setUntouched();
		form.$setPristine();
	}

	validateLenghtPassword(password) {

		// console.log('validateLenghtPassword: ', password.length);
		if (password.length >= 8) {
			return true;
		} else {

			return false;
		}
	}

	hasLetterPassword(password) {

		// console.log('hasLetterPassword: ', password.search(/[a-zA-Z]$/));
		if (password.search(/[a-zA-Z]/) >= 0) {
			return true;
		} else {

			return false;
		}
	}

	hasNumberPassword(password) {

		// console.log('hasNumberPassword: ', password.search(/[0-9]$/));
		if (password.search(/[0-9]/) >= 0) {
			return true;
		} else {

			return false;
		}
	}

	hasSymbolPassword(password) {

		// console.log('hasSymbolPassword: ', password.search(/[!#$%\-_=+<>]+/));
		if (password.search(/[!#$%\-_=+<>]+/) >= 0) {
			return true;
		} else {

			return false;
		}
	}

	isMoreThanDate(date, minDate) {
		// console.log('isMoreThanDate', date, minDate);
		let result = false;

		if (date.indexOf('/') !== -1) {
			const splitedDate = date.split('/');
			date = new Date(splitedDate[2], splitedDate[1], splitedDate[0]);
		}
		// console.log('isMoreThanDate parsed', date, minDate);

		if ((typeof (date) === "object") && (typeof (minDate) === "object")) {
			result = date.getTime() >= minDate.getTime();
		}

		return result;
	}

	validateMinDate(formDate, minDate) {
		// console.log('validateMinDate', formDate, minDate);

		if (!formDate.$viewValue) {

			formDate.$setValidity('minDate', true);
			return;
		}

		const isMoreThanMinDate = this.isMoreThanDate(formDate.$viewValue, minDate);

		// console.log('isMoreThanMinDate', isMoreThanMinDate);
		formDate.$setValidity('minDate', isMoreThanMinDate);
	}
}

const _badExpressions = [
	"ABESTADO",
	"ARROMBADA",
	"ARROMBADO",
	"BABACA",
	"BAITOLA",
	"BICHA",
	"BICHONA",
	"BIXA",
	"BOIOLA",
	"BOQUETEIRA",
	"BOQUETEIRO",
	"BOQUETERA",
	"BOQUETERO",
	"BOSTA",
	"CACHORRA",
	"CADASTRADA",
	"CADASTRADO",
	"CAGALHAO",
	"CARALHA",
	"CARALHO",
	"CARALHUDO",
	"CASSETE",
	"CEQUELADA",
	"CEQUELADO",
	"CHUPADOR",
	"CHUPADORA",
	"CHUPETINHA",
	"CORRUPTO",
	"CU",
	"CUECAO",
	"CUZAO",
	"DADEIRA",
	"DECLARADA",
	"DECLARADO",
	"DECLARAR",
	"FILHO DA PUTA",
	"FODA",
	"FODAO",
	"FODIDO",
	"FUDIDO",
	"PROGRAMA",
	"IDIOTA",
	"INEXISTENTE",
	"INFORMADA",
	"INFORMADO",
	"LADRAO",
	"MARAFONA",
	"MERDA",
	"MERDAO",
	"MERETRIZ",
	"NAO CONSTA",
	"OMITIDA",
	"OMITIDO",
	"OTARIO,PEIDAO",
	"PEIDO",
	"PEIDORREIRO",
	"PIRANHUDA",
	"PIROCAO",
	"PIROCUDO",
	"PITBITOCA",
	"PITCHBICHA",
	"PITCHBITOCA",
	"PITHBICHA",
	"PITHBITOCA",
	"PITIBICHA",
	"PORRA",
	"PREENCHIDA",
	"PREENCHIDO",
	"PUNHETA",
	"PUNHETEIRO",
	"QUENGA",
	"RAMEIRA",
	"RAPARIGA",
	"RETARDADO",
	"SAFADA",
	"SAFADO",
	"SEQUELADA",
	"SEQUELADO",
	"SEU CREISSOM",
	"SEU CREISSON",
	"SEU CREYSSOM",
	"SEU CREYSSON",
	"TESAO",
	"TESUDA",
	"TESUDO",
	"TEZAO",
	"TEZUDA",
	"TEZUDO",
	"TCHUTCHUCA",
	"TROUXA",
	"TROXA",
	"VACILAO",
	"VAGABUNDA",
	"VAGABUNDO",
	"VEIACA",
	"VEIACO",
	"VIADINHO",
	"XERECA",
	"XOTA",
	"XOXOTA",
	"TESTE",
	"PUTA"
];

module.exports = validationFormFields;
