"use strict";

const routesConfiguration = require("./main-routes");
const mainController = require("./main-controller");

const mainModule = angular
	.module("cap.public.mainModule", [
		"ui.router",
		"ui.bootstrap",
		"ui.utils.masks",
		"ngMask"
	])
	.controller("mainController", mainController)
	.config(routesConfiguration);

module.exports = mainModule;
