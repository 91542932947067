'use strict';

const defaultParam = true;

class VisualForceApi {

	/* @ngInject */
	constructor($q, $window, coletorApi) {
		this.$window = $window;
		this.$q = $q;
		this.coletorApi = coletorApi;
	}

	invoke(remoteAction, ...parameters) {

		const deferred = this.$q.defer();

		// @todo Gambiarra, ao que parece Visualforce não aceita não ter parametros
		parameters = parameters.length > 0 ? parameters : [{ defaultParam }];
		parameters = angular.fromJson(angular.toJson(parameters));

		Visualforce.remoting.Manager.invokeAction(
			remoteAction, ...parameters, (result, event) => {
				if (event.type === 'exception') {
					if (event.message.includes('Solicitação remota inválida para sua sessão') === true || event.message.includes('Page not allowed for the profile')) {
						this.$window.location.reload();
					}
					deferred.resolve({ isSuccesful: false, errorMessages: [event.message] });
				} else if (event.status) {
					if (result.hasOwnProperty('isSuccessful') && result.isSuccessful === false) {
						console.log('Volta do erro -> ', result);
						console.log('request -> ', parameters);
						this.coletorApi.enviaErro('BackEnd', JSON.stringify(angular.toJson(result)) /* + JSON.stringify(angular.toJson(parameters))*/ );
					}
					deferred.resolve(result);
				} else {
					deferred.reject(event);
				}

			}, { buffer: false, escape: false, timeout: 120000 });

		return deferred.promise;
	}
}

module.exports = VisualForceApi;
