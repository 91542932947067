'use strict';

class changePasswordApi {

	/* @ngInject */
	constructor($q, $timeout, visualForceApi) {
		this.visualForceApi = visualForceApi;
	}

	sendNewPassword(form) {
		console.log(form);
		return this.visualForceApi.invoke(
			'CapCommunitySigninControllerExt.changePassword', form);
	}
}

module.exports = changePasswordApi;
