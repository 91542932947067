'use strict';

class simulatorPrestamistaAvulsoController {
	/* @ngInject */
	constructor($scope, $window, sfConfig, simulatorApi, simulatorOptionalCharges, validationFormFields, feedbackMessages) {
		this.sfResource = sfConfig.resource;
		this.simulatorApi = simulatorApi;
		this.optionalCharges = simulatorOptionalCharges;
		this.valid = validationFormFields;
		this.messages = feedbackMessages;
		this.$scope = $scope;
		this.$window = $window;

		this.formSimulate = {};
		this.vigencias = ['12', '24', '30', '36'];
		this.formSendEmail = {};
		this.simulationActive = null;
		this.firstMessage = null;
		this.emailSent = false;

		this.tipoSimulador = 'PA';

		this.resultado = false;
		this.nomeCliente = '';
		this.valorAluguel = '';
		this.multiplicador = '';
		this.habilitarExecucao = false;

		this.secured = false;
		this.isActibe = false;

		this.btnNovaSimulacao = `${sfConfig.resource}/images/simulador/btn-nova-simulacao.png`;
		this.btnQueroContratar = `${sfConfig.resource}/images/simulador/btn-quero-contratar.png`;
		this.btnBaixeAqui = `${sfConfig.resource}/images/simulador/btn-baixe-aqui.png`;

		console.log('SIMULADOR PA!!!!!');

		this.checkSimulationStatus();
		this.setOptionalChargesOpenPristine();

		this.setFormSimulatePristine();
		this.setFormSendEmailPristine();

		/** wtf - tempo */
		$('.imagemSimulador').hide();
		$('.areaLogin').hide();
		$('.areaContent').removeClass('col-md-7');

		// Obtém a URL atual
		const urlAtual = window.location.href;
		console.log("urlAtual: ", urlAtual);

		// Verifica se a URL contém "/secured"
		/*if (urlAtual.includes("/secured")) {
			console.log("simulador rodndo na area logada");
			this.secured = true;
		} else {
			console.log("A URL NÃO contém '/secured'");
		}*/

		// setInterval(() => { console.log(this.formSimulate, this.formSendEmail); }, 15000);
	}

	set isOptionalChargesOpen(status) {
		// console.log('set isOptionalChargesOpen', status);

		this.checkFirstOptionalChargesLoad();

		this._isOptionalChargesOpen = status;
	}

	get isOptionalChargesOpen() {
		// console.log('get isOptionalChargesOpen', this._isOptionalChargesOpen);

		return this._isOptionalChargesOpen;
	}

	isResultado() {
		console.log('isResultado: ', this.resultado);
		//this.checkHabilitarExecucao();
		return this.resultado;
	}

	reset() {
		console.log("reset ...: ");
		this.resultado = false;
		this.habilitarExecucao = false;
		this.formSimulate.nome = "";
		this.formSimulate.valor = "";
		this.formSimulate.multiplicador = "";
		this.formSimulate.dataNascimento = "";
		this.formSimulate.cpf = "";
	}

	contrateAqui() {
		sessionStorage.setItem("tipo_lista", "D");
		console.log("cpf para contratacao: ", this.formSimulate.cpf);
		sessionStorage.setItem("cpf_contrate_aqui", this.formSimulate.cpf);
		this.$window.scrollTo(0, 0);
		this.$state.go("proposta.new");
	}

	checkHabilitarExecucao() {
		console.log("optionalCharges: ", this.formSimulate);

		if (
			this.formSimulate.nome &&
			this.formSimulate.valor &&
			this.formSimulate.multiplicador &&
			this.formSimulate.dataNascimento &&
			this.formSimulate.cpf
		) {
			//verifica se a data de nascimento é valida

			console.log("dataNascimento: ", this.formSimulate.dataNascimento);

			if (this.validarData(this.formSimulate.dataNascimento)) {
				if (this.validarIdadeDataNascimento(this.formSimulate.dataNascimento)) {
					this.habilitarExecucao = true;
					console.log("EXECUCAO HABILITADA");
					return true;
				}
			}

			this.habilitarExecucao = false;
			console.log("EXECUCAO DESABILITADA");
			return false;
		} else {
			this.habilitarExecucao = false;
			console.log("EXECUCAO DESABILITADA");
			return false;
		}
	}

	validarIdadeDataNascimento(dataNascimentoTexto) {
		const partesData = dataNascimentoTexto.split("/");

		if (partesData.length !== 3) {
			return false;
		}

		const dia = parseInt(partesData[0]);
		const mes = parseInt(partesData[1]);
		const ano = parseInt(partesData[2]);

		if (isNaN(dia) || isNaN(mes) || isNaN(ano)) {
			return false;
		}

		const dataNascimento = new Date(ano, mes - 1, dia);

		if (
			dataNascimento.getFullYear() !== ano ||
			dataNascimento.getMonth() !== mes - 1 ||
			dataNascimento.getDate() !== dia
		) {
			return false;
		}

		const hoje = new Date();
		let idade = hoje.getFullYear() - dataNascimento.getFullYear();

		if (
			hoje.getMonth() < dataNascimento.getMonth() ||
			(hoje.getMonth() === dataNascimento.getMonth() &&
				hoje.getDate() < dataNascimento.getDate())
		) {
			idade--;
		}

		const resultadoIdade = idade >= 18 && idade <= 65;
		console.log("resultadoIdade: ", resultadoIdade);
		return resultadoIdade;
	}

	validarFormatoData(dataNascimentoTexto) {
		const regexFormato = /^\d{2}\/\d{2}\/\d{4}$/;
		return regexFormato.test(dataNascimentoTexto);
	}

	validarData(dataNascimentoTexto) {
		if (!this.validarFormatoData(dataNascimentoTexto)) {
			return false;
		}

		const partesData = dataNascimentoTexto.split("/");

		const dia = parseInt(partesData[0]);
		const mes = parseInt(partesData[1]);
		const ano = parseInt(partesData[2]);

		if (isNaN(dia) || isNaN(mes) || isNaN(ano)) {
			return false;
		}

		const dataNascimento = new Date(ano, mes - 1, dia);

		if (
			dataNascimento.getFullYear() !== ano ||
			dataNascimento.getMonth() !== mes - 1 ||
			dataNascimento.getDate() !== dia
		) {
			return false;
		}

		return true;
	}

	executarSimulador() {
		console.log("iniciando simulador");

		this.erroDG = null;
		this.isProcessando = true;
		this.resultado = false;

		this.simulatorApi
			.executeSimulatorDuplaGarantia(
				this.formSimulate.cpf,
				this.formSimulate.dataNascimento,
				this.formSimulate.multiplicador,
				Number(this.formSimulate.valor)
			)
			.then((response) => {
				console.log("resultado do simulador de dupla garantia: ", response);
				this.isProcessando = false;

				if (response.errorMessages) {
					console.log("identificado erro no simulador DG");
					this.resultado = false;

					try {
						this.erroDG = JSON.parse(response.errorMessages[0]).mensagem;
					} catch (error) {
						this.erroDG = `Ocorreu uma falha nao esperada: ${response.errorMessages}!`;
					}

					console.log("erroDG: ", this.erroDG);
				} else {
					this.resultado = true;
					this.formSimulate.valorSeguroPrestamista = response.valorPremioTotal;

					for (let i = 0; i < response.coberturas.length; i++) {
						if (response.coberturas[i].numero == 1) {
							this.formSimulate.anyCauseValueSISVIDA = response.coberturas[i].valorCapital;
						}
						if (response.coberturas[i].numero == 24) {
							this.formSimulate.totalByAccidentValueSISVIDA = response.coberturas[i].valorCapital;
						}
						if (response.coberturas[i].numero == 108) {
							this.formSimulate.rentLossValueSISVIDA = response.coberturas[i].valorCapital;
						}
					}

					this.formSimulate.cobertura_morte_calculada = this.formSimulate.anyCauseValueSISVIDA;
					this.formSimulate.cobertura_invalidez_calculada = this.formSimulate.totalByAccidentValueSISVIDA;
					this.formSimulate.cobertura_perda_emprego_calculada = this.formSimulate.rentLossValueSISVIDA;

					console.log('Forms: ', this.formSimulate);

				}
			});

		return true;
	}

	exportarPDF() {
		console.log('entrou no exportar PDF ...');

		this.simulatorApi.exportarPDF(this.tipoSimulador, this.formSimulate).then((response) => {
			console.log('resultado do export pdf: ', response);

			const pdfBase64 = response.pdfResultado;

			console.log('pdfBase64: ', pdfBase64);

			this.downloadPDF(pdfBase64);
		});
	}

	downloadPDF(pdfBase64) {
		const linkSource = `data:application/pdf;base64,${pdfBase64}`;
		const downloadLink = document.createElement('a');
		const fileName = 'simulação.pdf';
		downloadLink.href = linkSource;
		downloadLink.download = fileName;
		downloadLink.click();
	}

	checkFirstOptionalChargesLoad() {
		// console.log('set checkFirstOptionalChargesLoad', this.hasOptionalChargesOpened);

		if (!this.hasOptionalChargesOpened) {
			this.hasOptionalChargesOpened = true;

			this.optionalCharges.setDefaultPristine(this.formSimulate);
		}
	}

	setFormSimulatePristine() {
		this.formSimulate = {
			nome: null,
			valor: null,
			multiplicador: null,
			resultado: null
		};

		this.optionalCharge = {
			name: null,
			value: null
		};
	}

	setFormSendEmailPristine() {
		// console.log('setFormSendEmailPristine', this.formSendEmail);

		this.formSendEmail = {
			id: null,
			accountName: null,
			accountEmail: null,
			accountPhone: null
		};
	}

	resetFormSimulate(form) {
		this.messages.cleanMessages(this.$scope);

		this.setOptionalChargesOpenPristine();

		this.setFormSimulatePristine();
		this.optionalCharges.resetField(this.optionalCharge);

		form && this.valid.resetViewForm(form);
	}

	resetFormSendEmail(form) {
		// console.log('resetFormSendEmail', form);

		this.messages.cleanMessages(this.$scope);

		this.setFormSendEmailPristine();

		form.$setPristine();
		this.emailSent = false;

		this.valid.resetViewForm(form);
	}

	resetFormEmail(form) {
		// console.log('resetEmailForm()');

		this.messages.cleanMessages(this.$scope);
		form.$setPristine();
		this.formSendEmail.accountName = null;
		this.formSendEmail.accountEmail = null;
		this.formSendEmail.accountPhone = null;
		this.valid.resetViewForm(form);
		this.emailSent = false;
	}

	setOptionalChargesOpenPristine() {
		this._isOptionalChargesOpen = false;
		this.hasOptionalChargesOpened = false;
	}

	hasSimulation() {
		return !!this.formSendEmail.id;
	}

	removeInvalidOptionalCharge(form) {
		/*
		 * @warning Gambiarra por causa de valor zero para os campos IPTU e Condomínio, que
		 * estão chumbados na tela
		 */

		if (!this.hasOptionalChargesOpened) {
			return form;
		}

		const optionalCharges = form.lstOptionalCharges;

		for (let i = optionalCharges.length - 1; i >= 0; i--) {
			if (optionalCharges[i].value === 0) {
				this.optionalCharges.remove(i, form);
			}
		}

		return form;
	}

	submitDataToSimulate(form) {
		// console.log('submitDataToSimulate', form);
		if (this.isOptionalChargesOpen) {
			if (this.optionalCharge.name || this.optionalCharge.value) {
				this.optionalCharges.add(form, this.optionalCharge, this.formSimulate);
			} else {
				/* @todo gambiarra pq ficou mudando a regra de negócio,
				 * campo tinha validação no botão adicionar, agora ele
				 * age em vários momentos, precisa de refactory(como 80%
				 * desse projeto)
				 */
				this.optionalCharges.forceFieldsValidity(form);
			}
		}

		form.$setSubmitted();
		// console.log('setSubmitted', form);

		if (form.$invalid) {
			this.$window.scrollTo(0, 0);
			return;
		}

		/*
		 * @warning Gambiarra por causa de valor zero para os campos IPTU e Condomínio, que
		 * estão chumbados na tela
		 */
		const formModel = this.removeInvalidOptionalCharge(angular.copy(this.formSimulate));

		this.submit('sendDataToSimulate', formModel).then((response) => {
			// console.log('submitDataToSimulate response', response);

			if (!response) {
				return;
			}

			this.formSendEmail.id = response.id;
			this.simulated = response;
		});
	}

	submitSimulationToEmail(form) {
		// console.log('submitSimulationToEmail', form);

		form.$setSubmitted();

		if (form.$invalid) {
			this.$window.scrollTo(0, 0);

			const invalidMessage = 'É necessário preencher os campos Nome, Email e Telefone';

			this.messages.showErrorMessage(this.$scope, invalidMessage);

			return;
		}

		this.submit('sendSimulationToEmail', this.formSendEmail).then((status) => {
			if (!status) {
				this.emailSent = false;
				return;
			}

			this.$window.scrollTo(0, 0);

			const successMessage = 'Mensagem enviada com sucesso!';

			this.messages.showSuccessMessage(this.$scope, successMessage);
			this.emailSent = true;
		});
	}

	submit(type, form) {
		// console.log('submit', type, form);

		this.messages.cleanMessages(this.$scope);

		this.$scope.$emit('changeMainShowLoading', true);

		return this.simulatorApi[type](form).then((response) => {
			// console.log('submit response', response);

			this.$scope.$emit('changeMainShowLoading', false);

			this.$window.scrollTo(0, 0);

			if (response.isSuccessful) {
				return response;
			}

			const errorMessages = angular.toJson(response.errorMessages);

			// console.log(response);

			if (response.validation) {}

			this.messages.showErrorMessage(this.$scope, errorMessages);

			return false;
		});
	}

	checkSimulationStatus() {
		// console.log('checkSimulationStatus()');

		this.messages.cleanMessages(this.$scope);

		this.$scope.$emit('changeMainShowLoading', true);

		// console.log('checkSimulationStatus() response: ', response);

		this.$scope.$emit('changeMainShowLoading', false);

		this.$window.scrollTo(0, 0);

		this.firstMessage = 'teste simulador';
		this.simulationActive = true;

		// const errorMessages = angular.toJson(response.errorMessages);
		// this.messages.showErrorMessage(this.$scope, errorMessages);

		return false;
	}
}

module.exports = simulatorPrestamistaAvulsoController;
