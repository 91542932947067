'use strict';

class ColetorApi {

	/* @ngInject */
	constructor(sfConfig) {
		this.url = window.location.href;
		this.agent = window.navigator.userAgent;
		this.language = window.navigator.language;
		this.cookieEnabled = window.navigator.cookieEnabled;
		this.defineAmbiente();

		this.config = angular.toJson(sfConfig);
		this.userId = sfConfig.idUSer;
		this.accountId = sfConfig.idUSerAccount;
	}

	defineAmbiente() {
		this.ambiente = "PRD";
		if (this.url.includes("https://uat-sul-america")) this.ambiente = "UAT";
		else if (this.url.includes("https://capdev") && this.url.includes("sul-america.cs")) this.ambiente = `DEV${this.url.substring(14, 16)}`;
		else if (this.url.includes("https://caphml") && this.url.includes("sul-america.cs")) this.ambiente = `HML`;
		else if (this.url.includes("https://caphotfix") && this.url.includes("sul-america.cs")) this.ambiente = `HOTFX`;
	}

	enviaErro(errorName, errorMessage) {
		try {
			$.ajax({
				url: "https://api-h.caponline.com.br/capservice/rest/logapp/saveException",
				type: "POST",
				data: {
					ambiente: this.ambiente,
					userId: this.userId,
					accountId: this.accountId,
					config: this.config,
					errorName,
					errorMessage,
					url: this.url,
					agent: this.agent,
					language: this.language,
					cookieEnabled: this.cookieEnabled
				}
			});
		} catch (err) {
			console.log(err);
		}
	}

	getAmbiente() {
		return this.ambiente;
	}
}

module.exports = ColetorApi;
