'use strict';

class simulatorController {
	/* @ngInject */
	constructor($scope, $state, $window, sfConfig, simulatorApi, simulatorOptionalCharges, validationFormFields, feedbackMessages) {
		this.sfResource = sfConfig.resource;
		this.simulatorApi = simulatorApi;
		this.optionalCharges = simulatorOptionalCharges;
		this.valid = validationFormFields;
		this.messages = feedbackMessages;
		this.$scope = $scope;
		this.$window = $window;
		this.$state = $state;

		this.formSimulate = {};
		this.formSendEmail = {};
		this.simulationActive = null;
		this.firstMessage = null;
		this.emailSent = false;

		this.imageDuplaGarantia = `${sfConfig.resource}/images/simulador/IMG_DuplaGarantia.jpg`;
		this.imageGarantiaAluguel = `${sfConfig.resource}/images/simulador/IMG_GarantiaDeAluguel.jpg`;
		this.imagePrestamistaAvulso = `${sfConfig.resource}/images/simulador/IMG_PrestamistaAvulso.jpg`;

		console.log('INFERNO00000 TESTE');

		/** wtf - tempo */
		$('.imagemSimulador').hide();
		$('.areaLogin').hide();
		$('.areaContent').removeClass('col-md-7');

		// setInterval(() => { console.log(this.formSimulate, this.formSendEmail); }, 15000);
	}

	redirectSimuladorGarantiaAluguel() {
		console.log('-> redirectSimuladorGarantiaAluguel ...');
		this.$window.scrollTo(0, 0);
		this.$state.go('proposta.new');
	}

	redirectSimuladorDuplaGarantia() {
		console.log('-> redirectSimuladorDuplaGarantia ...');
		this.$window.scrollTo(0, 0);
		this.$state.go('proposta.new');
	}

	redirectSimuladorPrestamistaAvulso() {
		console.log('-> redirectSimuladorPrestamistaAvulso ...');
		this.$window.scrollTo(0, 0);
		this.$state.go('proposta.new');
	}
}

module.exports = simulatorController;
