"use strict";

class signinService {
	/* @ngInject */
	constructor(visualForceApi) {
		this.visualForceApi = visualForceApi;
	}

	invokeSignin(user) {
		return this.visualForceApi.invoke(
			"CapCommunitySigninControllerExt.signIn",
			user
		);
	}

	invokeForgotPassword(username) {
		return this.visualForceApi.invoke(
			"CapCommunitySigninControllerExt.forgotPassword",
			username
		);
	}

	invokeForgotPasswordConfirmation(password, verifyPassword) {
		console.log("invokeForgotPasswordConfirmation starting ...");
		const changePasswordKey = sessionStorage.getItem("change_password_key");

		return this.visualForceApi.invoke(
			"CapCommunitySigninControllerExt.forgotPasswordConfirmation", { password, verifyPassword, changePasswordKey }
		);
	}
}

module.exports = signinService;
