'use strict';

class homeController {

	/* @ngInject */
	constructor(sfConfig, homeBannerApiService, feedbackMessages) {

		this.sfConfig = sfConfig;
		this.homeBannerApi = homeBannerApiService;
		this.messages = feedbackMessages;
		this.messages.cleanMessages(this.$scope);

		this.myInterval = 5000;
		this.noWrapSlides = false;
		this.active = 0;

		this.bannersHome = [];
		this.getHomeBanners();

		$('.imagemSimulador').show();
		$('.areaLogin').show();
	}

	getHomeBanners() {

		this.homeBannerApi.getBannerList().then((response) => {

			// this.showLoading = false;

			if (!response.isSuccessful) {

				// vixi
			} else {

				this.bannersHome = response.banners;
			}
		});
	}

}

module.exports = homeController;
