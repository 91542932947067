'use strict';

const exceptionHandlerModule = angular.module('exceptionHandlerModule', [])
	.factory('$exceptionHandler', [($injector, $log, sfConfig, coletorApi) => {
		return (exception, cause) => {
			const feedbackMessages = $injector.get("feedbackMessages");

			let at = "";
			if (!!exception.stack) {
				at = exception.stack.split('\n')[1].trim();
			} else {
				at = "(no stack)";
			}
			const errorName = exception.name;
			const errorMessage = `${exception.message} ${at} ${cause}`;

			const ambiente = coletorApi.getAmbiente();
			// Exibe mensagem na tela em ambientes diferente de produção
			// -------------------------------------------------------------------------------------------------
			if ((ambiente !== "PRD") && (ambiente !== "UAT")) {
				feedbackMessages.showErrorMessage(null, `${ambiente} - ${errorMessage}`);
			}

			// Tenta enviar o erro para o coletor de exceções --------------------------------------------------
			// -------------------------------------------------------------------------------------------------
			coletorApi.enviaErro(errorName, errorMessage);

			// Printa o erro
			$log.error(errorMessage);
			console.log(exception.stack);
		};
	}]);

export default exceptionHandlerModule;
