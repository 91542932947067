'use strict';

class ordernationHelper {

	// @ngInject
	constructor() {
		this.setPristine();
	}

	setPristine() {
		this.data = {
			field: 'default',
			direction: 'ASC'
		};
	}

	getOrderStyle(field) {
		const style = 'seta-order ';
		if (this.data.field === field) {
			return style + (this.data.direction === 'ASC' ? 'up' : 'down');
		} else {
			return '';
		}
	}

	changeOrder(field) {
		if ((this.data.field === field) && (this.data.field !== '')) {
			this.data.direction = (this.data.direction == 'ASC' ? 'DESC' : 'ASC');
		} else {
			this.data.field = field;
			this.data.direction = 'ASC';
		}
	}

}

module.exports = ordernationHelper;
