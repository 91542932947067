"use strict";

const VisualForceApi = require("./sfdc/visual-force-api.service");
const coletorApi = require("./coletor/coletor-api.service");
const validationModule = require("./validations/validations.module");
const simulatorModule = require("./simulator/simulator.module");
const feedbackModule = require("./feedback/feedback.module");
const helpersModule = require("./helpers/helpers.module");
const filtersModule = require("./filters/filters.module");
const exceptionHandlerModule =
	require("./exceptionHandler/exceptionHandler.module").default;
const percentFieldDirective = require("./directives/percentfield.directive");

const coreModule = angular
	.module("cap.core", [
		simulatorModule.name,
		validationModule.name,
		feedbackModule.name,
		helpersModule.name,
		filtersModule.name,
		exceptionHandlerModule.name,
		"ui.bootstrap",
		"ui.mask",
		"angucomplete-alt",
		"ngSanitize",
		"ngAnimate"
	])
	.service("visualForceApi", VisualForceApi)
	.service("coletorApi", coletorApi)
	.directive("percentField", () => new percentFieldDirective());

module.exports = coreModule;
