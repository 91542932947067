'use strict';

const validationFormFieldsService = require('./form-fields.service');
// const validNameDirective = require('./valid-name.directive');

const validationModule = angular.module('validation.formFields', [])
	.service('validationFormFields', validationFormFieldsService);
// .directive('validNameDirective', validNameDirective);

module.exports = validationModule;
