'use strict';

const paginationHelper = require('./pagination.service');
const autoCompleteHelper = require('./auto-complete.service');
const ordenationHelper = require('./ordenation.service');
const cepHelper = require('./cep.service');
const dadosBancariosHelper = require('./dadosBancarios.service');
const crivoHelper = require('./crivo.service');
const breadcrumbHelper = require('./breadcrumbHelper');
const utilsHelper = require('./utils.service');

const helpersModule = angular.module('cap.core.helpersModule', [])
	.service('paginationHelper', paginationHelper)
	.service('autoCompleteHelper', autoCompleteHelper)
	.service('ordenationHelper', ordenationHelper)
	.service('cepHelper', cepHelper)
	.service('dadosBancariosHelper', dadosBancariosHelper)
	.service('crivoHelper', crivoHelper)
	.service('utilsHelper', utilsHelper)
	.service('breadcrumbHelper', breadcrumbHelper);

module.exports = helpersModule;
