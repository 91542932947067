'use strict';

class dadosBancariosHelper {

	// @ngInject
	constructor($q, visualForceApi) {
		this.visualForceApi = visualForceApi;
		this.remoteCallError = 0;
		this.tentativas = 3;
		this.$q = $q;
		this.setPristine();
	}

	setPristine() {
		this.setData({
			isValid: true
		});
	}

	setData(data) {
		this.data = data;
	}

	getData() {
		return this.data;
	}

	checaDadosBancarios(tipoConta, cpfCnpj, banco, agencia, agenciaDV, conta, contaDV) {
		// Valida se as informações necessárias para chamar o serviço estão
		// atribuidas corretamente

		console.log('--> entrou em checaDadosBancarios:', conta);

		const validacao = (tipoConta != null && tipoConta.length > 0 &&
			cpfCnpj != null && cpfCnpj.length >= 11 &&
			banco != null && banco.length > 0 &&
			agencia != null && agencia.length > 0 &&
			conta != null && conta.length > 1);

		console.log('--> entrou em checaDadosBancarios: ', validacao);

		return validacao;
	}

	consultaDadosBancariosSeValidos(tipoConta, cpfCnpj, banco, agencia, agenciaDV, conta, contaDV, callbackfunc) {

		console.log('callbackfunc: ', callbackfunc);
		const me = this;

		if (me.checaDadosBancarios(tipoConta, cpfCnpj, banco, agencia, agenciaDV, conta, contaDV)) {

			const response = { isSuccessful: true, isValid: true };
			me.setData(response);
			callbackfunc.call(this, me.getData());

			/* Comentado chamado do serviço de validação bancária
			console.log('Entrei no dadosBancariosHelper.consultaDadosBancariosSeValidos');

			// Após validar, chama serviço que retorna se os dados bancários são válidos
			me.callDadosBancariosSeValidos(tipoConta, cpfCnpj, banco, agencia, agenciaDV, conta, contaDV).then((response) => {

				console.log('Retorno do serviço de validacao bancaria: ', response);

				if (!response.isSuccessful) {
					me.remoteCallError++;
					me.tentativas--;
					me.atualizaMensagem();
				} else {
					me.remoteCallError = 0;
					me.tentativas = 3;
				}

				// Se o limite de chamadas com erro for atingido
				// Força o resultado de sucesso para prosseguir o processo.
				if (this.remoteCallErrorLimitWasExceded()) {
					response.isSuccessful = true;
					response.isValid = true;
				}

				me.setData(response);

				callbackfunc.call(this, me.getData());
			});
			*/

		} else {
			console.log('dados bancarios inválidos');
			this.remoteCallErrorMessage = 'dados bancários não informados';
			callbackfunc.call(this, me.getData());
		}
	}

	consultaDadosBancariosSeValidosPromisse(tipoConta, cpfCnpj, banco, agencia, agenciaDV, conta, contaDV) {

		const deferred = this.$q.defer();

		const me = this;

		if (me.checaDadosBancarios(tipoConta, cpfCnpj, banco, agencia, agenciaDV, conta, contaDV)) {
			const response = { isSuccessful: true, isValid: true };
			deferred.resolve(response);

			/* Comentado chamado do serviço de validação bancária
			// Após validar, chama serviço que retorna se os dados bancários são válidos
			me.callDadosBancariosSeValidos(tipoConta, cpfCnpj, banco, agencia, agenciaDV, conta, contaDV).then((response) => {

				if (!response.isSuccessful) {
					me.remoteCallError++;
					me.tentativas--;
					me.atualizaMensagem();
				} else {
					me.remoteCallError = 0;
					me.tentativas = 3;
				}

				if (this.remoteCallErrorLimitWasExceded()) {
					response.isSuccessful = true;
					response.isValid = true;
				}

				deferred.resolve(response);
			});
			*/
		}

		return deferred.promise;
	}

	atualizaMensagem() {
		this.remoteCallErrorMessage = `Serviço de dados bancários indisponível no momento. Faça mais ${this.tentativas} tentativas para prosseguir!`;
		this.remoteCallErrorMessage = this.remoteCallErrorMessage.replace("1 tentativas", "uma tentativa");
	}

	// Limita a 3 tentativas
	remoteCallErrorLimitWasExceded() {
		return (this.remoteCallError >= 3);
	}

	callDadosBancariosSeValidos(tipoConta, cpfCnpj, banco, agencia, agenciaDV, conta, contaDV) {
		const request = { tipoConta, cpfCnpj, banco, agencia, agenciaDV, conta, contaDV };
		return this.visualForceApi.invoke('CapCommunityDadosBancariosCtrlExt.consultaDadosBancariosSeValidos', request);
	}
}

module.exports = dadosBancariosHelper;
