"use strict";

class simulatorGarantiaAluguelController {
	/* @ngInject */
	constructor(
		$scope,
		$window,
		sfConfig,
		simulatorApi,
		simulatorOptionalCharges,
		validationFormFields,
		feedbackMessages
	) {
		this.sfResource = sfConfig.resource;
		this.simulatorApi = simulatorApi;
		this.optionalCharges = simulatorOptionalCharges;
		this.valid = validationFormFields;
		this.messages = feedbackMessages;
		this.$scope = $scope;
		this.$window = $window;

		this.formSimulate = {};
		this.formSendEmail = {};
		this.simulationActive = null;
		this.firstMessage = null;
		this.emailSent = false;

		this.tipoSimulador = "GA";

		this.resultado = false;
		this.nomeCliente = "";
		this.valorAluguel = "";
		this.multiplicador = "";
		this.habilitarExecucao = false;

		console.log("SIMULADOR GA!!!!!");

		this.checkSimulationStatus();
		this.setOptionalChargesOpenPristine();

		this.setFormSimulatePristine();
		this.setFormSendEmailPristine();

		/** wtf - tempo */
		$(".imagemSimulador").hide();
		$(".areaLogin").hide();
		$(".areaContent").removeClass("col-md-7");

		// setInterval(() => { console.log(this.formSimulate, this.formSendEmail); }, 15000);
	}

	set isOptionalChargesOpen(status) {
		// console.log('set isOptionalChargesOpen', status);

		this.checkFirstOptionalChargesLoad();

		this._isOptionalChargesOpen = status;
	}

	get isOptionalChargesOpen() {
		// console.log('get isOptionalChargesOpen', this._isOptionalChargesOpen);

		return this._isOptionalChargesOpen;
	}

	isResultado() {
		console.log("isResultado: ", this.resultado);
		//this.checkHabilitarExecucao();
		return this.resultado;
	}

	reset() {
		console.log("reset ...: ");
		this.resultado = false;
		this.habilitarExecucao = false;
		this.formSimulate.nome = "";
		this.formSimulate.valor = "";
		this.formSimulate.multiplicador = "";
	}

	checkHabilitarExecucao() {
		console.log("optionalCharges: ", this.formSimulate);

		if (
			this.formSimulate.nome &&
			this.formSimulate.valor &&
			this.formSimulate.multiplicador
		) {
			this.habilitarExecucao = true;
			return true;
		} else {
			this.habilitarExecucao = false;
			return false;
		}
	}

	executarSimulador() {
		this.resultado = true;

		console.log("iniciando simulador");

		this.resultado = true;

		const valorTotal =
			Number(this.formSimulate.valor) * Number(this.formSimulate.multiplicador);

		console.log("valorTotal: ", valorTotal);

		this.simulatorApi.executeSimulator(valorTotal).then((response) => {
			console.log("resultado da busca de parcelas: ", response);

			if (response.status && response.status == "error") {
				console.log("identificado erro na busca de parcelas");
				this.erroParcelas = response.message;
			} else {
				this.parcelasResultado = response.resultado;
				this.parcelas = [];

				for (const p of response.resultado) {
					//p.label = 'R$ ' + p.valorCobrado + ' - ' + p.numeroQtdParcelas + 'x de R$ ' + p.valorParcela;
					if (p.numeroQtdParcelas > 1)
						p.label = `${p.numeroQtdParcelas}x R$ ${p.valorParcela}  (R$ ${p.valorCobrado})`;
					else p.label = `à vista R$ ${p.valorParcela}  (R$ ${p.valorCobrado})`;

					this.parcelas.push(p);
				}

				this.formSimulate.resultado = response.resultado;

				console.log("this.parcelas: ", this.parcelas);
			}
		});

		return true;
	}

	exportarPDF() {
		console.log("entrou no exportar PDF ...");

		this.simulatorApi
			.exportarPDF(this.tipoSimulador, this.formSimulate)
			.then((response) => {
				console.log("resultado da busca de parcelas: ", response);

				const pdfBase64 = response.pdfResultado;

				console.log("pdfBase64: ", pdfBase64);

				this.downloadPDF(pdfBase64);
			});
	}

	downloadPDF(pdfBase64) {
		const linkSource = `data:application/pdf;base64,${pdfBase64}`;
		const downloadLink = document.createElement("a");
		const fileName = "simulação.pdf";
		downloadLink.href = linkSource;
		downloadLink.download = fileName;
		downloadLink.click();
	}

	checkFirstOptionalChargesLoad() {
		// console.log('set checkFirstOptionalChargesLoad', this.hasOptionalChargesOpened);

		if (!this.hasOptionalChargesOpened) {
			this.hasOptionalChargesOpened = true;

			this.optionalCharges.setDefaultPristine(this.formSimulate);
		}
	}

	setFormSimulatePristine() {
		this.formSimulate = {
			nome: null,
			valor: null,
			multiplicador: null,
			resultado: null
		};

		this.optionalCharge = {
			name: null,
			value: null
		};
	}

	setFormSendEmailPristine() {
		// console.log('setFormSendEmailPristine', this.formSendEmail);

		this.formSendEmail = {
			id: null,
			accountName: null,
			accountEmail: null,
			accountPhone: null
		};
	}

	resetFormSimulate(form) {
		this.messages.cleanMessages(this.$scope);

		this.setOptionalChargesOpenPristine();

		this.setFormSimulatePristine();
		this.optionalCharges.resetField(this.optionalCharge);

		form && this.valid.resetViewForm(form);
	}

	resetFormSendEmail(form) {
		// console.log('resetFormSendEmail', form);

		this.messages.cleanMessages(this.$scope);

		this.setFormSendEmailPristine();

		form.$setPristine();
		this.emailSent = false;

		this.valid.resetViewForm(form);
	}

	resetFormEmail(form) {
		// console.log('resetEmailForm()');

		this.messages.cleanMessages(this.$scope);
		form.$setPristine();
		this.formSendEmail.accountName = null;
		this.formSendEmail.accountEmail = null;
		this.formSendEmail.accountPhone = null;
		this.valid.resetViewForm(form);
		this.emailSent = false;
	}

	setOptionalChargesOpenPristine() {
		this._isOptionalChargesOpen = false;
		this.hasOptionalChargesOpened = false;
	}

	hasSimulation() {
		return !!this.formSendEmail.id;
	}

	removeInvalidOptionalCharge(form) {
		/*
		 * @warning Gambiarra por causa de valor zero para os campos IPTU e Condomínio, que
		 * estão chumbados na tela
		 */

		if (!this.hasOptionalChargesOpened) {
			return form;
		}

		const optionalCharges = form.lstOptionalCharges;

		for (let i = optionalCharges.length - 1; i >= 0; i--) {
			if (optionalCharges[i].value === 0) {
				this.optionalCharges.remove(i, form);
			}
		}

		return form;
	}

	submitDataToSimulate(form) {
		// console.log('submitDataToSimulate', form);
		if (this.isOptionalChargesOpen) {
			if (this.optionalCharge.name || this.optionalCharge.value) {
				this.optionalCharges.add(form, this.optionalCharge, this.formSimulate);
			} else {
				/* @todo gambiarra pq ficou mudando a regra de negócio,
				 * campo tinha validação no botão adicionar, agora ele
				 * age em vários momentos, precisa de refactory(como 80%
				 * desse projeto)
				 */
				this.optionalCharges.forceFieldsValidity(form);
			}
		}

		form.$setSubmitted();
		// console.log('setSubmitted', form);

		if (form.$invalid) {
			this.$window.scrollTo(0, 0);
			return;
		}

		/*
		 * @warning Gambiarra por causa de valor zero para os campos IPTU e Condomínio, que
		 * estão chumbados na tela
		 */
		const formModel = this.removeInvalidOptionalCharge(
			angular.copy(this.formSimulate)
		);

		this.submit("sendDataToSimulate", formModel).then((response) => {
			// console.log('submitDataToSimulate response', response);

			if (!response) {
				return;
			}

			this.formSendEmail.id = response.id;
			this.simulated = response;
		});
	}

	submitSimulationToEmail(form) {
		// console.log('submitSimulationToEmail', form);

		form.$setSubmitted();

		if (form.$invalid) {
			this.$window.scrollTo(0, 0);

			const invalidMessage =
				"É necessário preencher os campos Nome, Email e Telefone";

			this.messages.showErrorMessage(this.$scope, invalidMessage);

			return;
		}

		this.submit("sendSimulationToEmail", this.formSendEmail).then((status) => {
			if (!status) {
				this.emailSent = false;
				return;
			}

			this.$window.scrollTo(0, 0);

			const successMessage = "Mensagem enviada com sucesso!";

			this.messages.showSuccessMessage(this.$scope, successMessage);
			this.emailSent = true;
		});
	}

	submit(type, form) {
		// console.log('submit', type, form);

		this.messages.cleanMessages(this.$scope);

		this.$scope.$emit("changeMainShowLoading", true);

		return this.simulatorApi[type](form).then((response) => {
			// console.log('submit response', response);

			this.$scope.$emit("changeMainShowLoading", false);

			this.$window.scrollTo(0, 0);

			if (response.isSuccessful) {
				return response;
			}

			const errorMessages = angular.toJson(response.errorMessages);

			// console.log(response);

			if (response.validation) {}

			this.messages.showErrorMessage(this.$scope, errorMessages);

			return false;
		});
	}

	checkSimulationStatus() {
		// console.log('checkSimulationStatus()');

		this.messages.cleanMessages(this.$scope);

		this.$scope.$emit("changeMainShowLoading", true);

		// console.log('checkSimulationStatus() response: ', response);

		this.$scope.$emit("changeMainShowLoading", false);

		this.$window.scrollTo(0, 0);

		this.firstMessage = "teste simulador";
		this.simulationActive = true;

		// const errorMessages = angular.toJson(response.errorMessages);
		// this.messages.showErrorMessage(this.$scope, errorMessages);

		return false;
	}
}

module.exports = simulatorGarantiaAluguelController;
