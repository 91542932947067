"use strict";

class signinController {
	/* @ngInject */
	constructor(signinService, $window, $scope) {
		console.log("--> signinController load");
		this.signinService = signinService;
		this.$window = $window;
		this.user = {
			username: {
				value: "",
				error: false,
				errorMessage: ""
			},
			password: {
				value: "",
				error: false,
				errorMessage: ""
			},
			token: "",
			rememberMe: false
		};

		this.errorMessage = "";
		this.forgotPasswordObject = {
			username: {
				value: "",
				error: false,
				errorMessage: ""
			},
			newPassword: {
				value: "",
				error: false,
				errorMessage: ""
			},
			newPasswordConfirmation: {
				value: "",
				error: false,
				errorMessage: ""
			}
		};
		this.isSubmitting = false;
		this.loadLocalStorage();

		this.changePasswordMode = false;
		this.change_password_key = $window.change_password_key;

		console.log(
			"inside angular: this.change_password_key: ",
			this.change_password_key
		);
	}

	getErrorMessage() {
		return this.errorMessage;
	}

	loadLocalStorage() {
		if (
			this.$window.localStorage.getItem("cap_username") != "" &&
			typeof this.$window.localStorage.getItem("cap_username") != "undefined" &&
			this.$window.localStorage.getItem("cap_username") != null
		) {
			this.user.username.value =
				this.$window.localStorage.getItem("cap_username");
			this.user.rememberMe = true;
		}
	}

	saveLocalStorage() {
		this.$window.localStorage.setItem("cap_username", this.user.username.value);
	}

	invokeSignin() {
		this.errorMessage = "";
		console.log("--> entrou na autenticacao!");

		const gtoken = this.$window.localStorage.getItem("gtoken");
		console.log("gtoken enviando pra o backend: ", this.user.token);

		if (this.singinValidation()) {
			if (this.user.rememberMe) {
				this.saveLocalStorage();
			}
			this.isSubmitting = true;
			this.signinService
				.invokeSignin({
					username: this.user.username.value,
					password: this.user.password.value,
					token: this.user.token
				})
				.then((response) => {
					console.log("----------> resultado signin: ", response);
					localStorage.setItem("cap_access_token", response.accessToken);

					if (response.isSuccessful) {
						response.url = response.url.replace(/&amp;/g, "&");
						console.log("autenticacao response url: ", response.url);
						this.$window.location.replace(response.url);
					} else {
						console.log("falha na autenticacao: ", response.errorMessages[0]);
						this.errorMessage = response.errorMessages[0].includes("login") ?
							"Verifique o usuário e senha informados. O usuário informado deve ser o e-mail cadastrado como login no momento da criação do usuário.<br><br>Caso tenha esquecido a senha, clique em Esqueceu a senha?, botão localizado abaixo.<br><br>Se ainda não for possível fazer login, entre em contato com o Centro de Relacionamento com o Corretor e Imobiliária através do telefone: 0800 285 3002. Horário de atendimento: Segunda a Sexta-Feira - 9:00 às 20:00." :
							response.errorMessages[0];
						// this.errorMessage = (response.errorMessages[0].includes('login')) ? 'O CapOnline está temporariamente indisponível. A aplicação ficará indisponível das 18 horas do dia 04/09/2020 até as 18 horas do dia 07/09/2020.' : response.errorMessages[0];
						$(".alert").show();
					}
					this.isSubmitting = false;
				});
		}
	}

	singinValidation() {
		this.user.username.error = false;
		this.user.password.error = false;
		console.log("validando signin: ", this.user);
		const gtoken = this.$window.localStorage.getItem("gtoken");
		console.log("gtoken: ", gtoken);

		if (
			this.user.username.value === "" ||
			typeof this.user.username.value === "undefined"
		) {
			this.user.username.error = true;
			this.user.username.errorMessage = "Campo obrigatório";
		} else if (!this.isValidEmail(this.user.username.value)) {
			this.user.username.error = true;
			this.user.username.errorMessage =
				"O nome de usuário deve ser um formato de email";
		}

		if (
			this.user.password.value === "" ||
			typeof this.user.password.value === "undefined"
		) {
			this.user.password.error = true;
			this.user.password.errorMessage = "Campo obrigatório";
		}
		return this.user.username.error || this.user.password.error ? false : true;
	}

	changeUsername() {
		this.user.username.error = false;
		this.user.username.errorMessage = "";
	}
	changeToken() {
		console.log("changeToken: ", this.user.token);
	}

	changePassword() {
		this.user.password.error = false;
		this.user.password.errorMessage = "";
	}

	forgotPassword() {
		this.errorMessage = "";
		console.log("forgot");
		if (this.forgotPasswordValidation()) {
			this.isSubmitting = true;
			this.signinService
				.invokeForgotPassword(this.forgotPasswordObject.username.value)
				.then((response) => {
					console.log(response);
					if (!response.isSuccessful) {
						this.errorMessage = response.errorMessages[0];
					} else {
						$(".modal-forgot").addClass("display");
						$(".modal-verify").removeClass("display");
						this.forgotPasswordObject = {
							username: {
								value: "",
								error: false,
								errorMessage: ""
							}
						};
					}
					this.isSubmitting = false;
				});
		}
	}

	forgotPasswordConfirmation() {
		this.errorMessage = "";
		console.log("forgot-confirmation");

		if (this.forgotPasswordConfirmationValidation()) {
			this.isSubmitting = true;
			this.signinService
				.invokeForgotPasswordConfirmation(
					this.forgotPasswordObject.newPassword.value,
					this.forgotPasswordObject.newPasswordConfirmation.value
				)
				.then((response) => {
					console.log(response);
					if (!response.isSuccessful) {
						this.errorMessage = response.errorMessages[0];
						this.forgotPasswordObject.newPassword.error = true;
						this.forgotPasswordObject.newPassword.errorMessage =
							response.errorMessages[0];
					} else {
						this.message = "";
						this.$window.location.replace(response.url);
					}
					this.isSubmitting = false;
				});
		}
	}

	forgotPasswordValidation() {
		this.forgotPasswordObject.username.error = false;
		if (
			this.forgotPasswordObject.username.value === "" ||
			typeof this.forgotPasswordObject.username.value === "undefined"
		) {
			this.forgotPasswordObject.username.error = true;
			this.forgotPasswordObject.username.errorMessage = "Campo obrigatório";
		} else if (!this.isValidEmail(this.forgotPasswordObject.username.value)) {
			this.forgotPasswordObject.username.error = true;
			this.forgotPasswordObject.username.errorMessage =
				"O nome de usuário deve ser um formato de email";
		}
		console.log("forgot validation ", this.forgotPasswordObject.username.error);
		return this.forgotPasswordObject.username.error ? false : true;
	}

	forgotPasswordConfirmationValidation() {
		this.forgotPasswordObject.newPassword.error = false;
		this.forgotPasswordObject.newPasswordConfirmation.error = false;

		console.log("validando confirmacao de reset de senha");

		if (
			this.forgotPasswordObject.newPassword.value === "" ||
			typeof this.forgotPasswordObject.newPassword.value === "undefined"
		) {
			this.forgotPasswordObject.newPassword.error = true;
			this.forgotPasswordObject.newPassword.errorMessage = "Campo obrigatório";
			return false;
		}

		console.log(
			"this.forgotPasswordObject.newPassword.errorMessage: ",
			this.forgotPasswordObject.newPassword.errorMessage
		);

		if (
			this.forgotPasswordObject.newPasswordConfirmation.value === "" ||
			typeof this.forgotPasswordObject.newPasswordConfirmation.value ===
			"undefined"
		) {
			this.forgotPasswordObject.newPassword.error = true;
			this.forgotPasswordObject.newPassword.errorMessage = "Campo obrigatório";
			return false;
		}

		console.log(
			"this.forgotPasswordObject.newPassword.errorMessage: ",
			this.forgotPasswordObject.newPassword.errorMessage
		);

		if (
			this.forgotPasswordObject.newPassword.value !==
			this.forgotPasswordObject.newPasswordConfirmation.value
		) {
			this.forgotPasswordObject.newPassword.error = true;
			this.forgotPasswordObject.newPassword.errorMessage =
				"A senha confirmada é diferente da senha informada";
			return false;
		}

		console.log(
			"this.forgotPasswordObject.newPassword.errorMessage: ",
			this.forgotPasswordObject.newPassword.errorMessage
		);

		if (
			!this.validateLenghtPassword(this.forgotPasswordObject.newPassword.value)
		) {
			this.forgotPasswordObject.newPassword.error = true;
			this.forgotPasswordObject.newPassword.errorMessage =
				"A senha deve ter um minimo de 8 caracteres";
			return false;
		}

		console.log(
			"this.forgotPasswordObject.newPassword.errorMessage: ",
			this.forgotPasswordObject.newPassword.errorMessage
		);

		if (!this.hasLetterPassword(this.forgotPasswordObject.newPassword.value)) {
			this.forgotPasswordObject.newPassword.error = true;
			this.forgotPasswordObject.newPassword.errorMessage =
				"A senha deve ter no mínimo uma letra";
			return false;
		}

		console.log(
			"this.forgotPasswordObject.newPassword.errorMessage: ",
			this.forgotPasswordObject.newPassword.errorMessage
		);

		if (!this.hasNumberPassword(this.forgotPasswordObject.newPassword.value)) {
			this.forgotPasswordObject.newPassword.error = true;
			this.forgotPasswordObject.newPassword.errorMessage =
				"A senha deve ter no mínimo um número";
			return false;
		}

		console.log(
			"this.forgotPasswordObject.newPassword.errorMessage: ",
			this.forgotPasswordObject.newPassword.errorMessage
		);

		if (!this.hasSymbolPassword(this.forgotPasswordObject.newPassword.value)) {
			this.forgotPasswordObject.newPassword.error = true;
			this.forgotPasswordObject.newPassword.errorMessage =
				"A senha deve ter no mínimo um caractere especial";
			return false;
		}

		console.log(
			"this.forgotPasswordObject.newPassword.errorMessage: ",
			this.forgotPasswordObject.newPassword.errorMessage
		);

		return this.forgotPasswordObject.newPassword.error ? false : true;
	}

	isValidEmail(email) {
		return true; //removido por conta dos usuarios com .icatu no final
		//const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const re =
			/^[a-zA-Z0-9][a-zA-Z0-9\._-]+@([a-zA-Z0-9\._-]+\.)[a-zA-Z-0-9]{2,3}$/;
		return re.test(email);
	}

	cancelForgotPassword() {
		$(".modal-forgot").addClass("display");
		$(".modal-login").removeClass("display");
		this.forgotPasswordObject = {
			username: {
				value: "",
				error: false,
				errorMessage: ""
			}
		};
		this.user.username.error = false;
		this.user.password.error = false;
		this.errorMessage = "";
	}

	onKeyPressEnter(event) {
		if (event.which === 13) {
			this.invokeSignin();
		}
	}

	validateLenghtPassword(password) {
		// console.log('validateLenghtPassword: ', password.length);
		if (password.length >= 8) {
			return true;
		} else {
			return false;
		}
	}

	hasLetterPassword(password) {
		// console.log('hasLetterPassword: ', password.search(/[a-zA-Z]$/));
		if (password.search(/[a-zA-Z]/) >= 0) {
			return true;
		} else {
			return false;
		}
	}

	hasNumberPassword(password) {
		// console.log('hasNumberPassword: ', password.search(/[0-9]$/));
		if (password.search(/[0-9]/) >= 0) {
			return true;
		} else {
			return false;
		}
	}

	hasSymbolPassword(password) {
		// console.log('hasSymbolPassword: ', password.search(/[!#$%\-_=+<>]+/));
		if (password.search(/[!#$%\-_=+<>]+/) >= 0) {
			return true;
		} else {
			return false;
		}
	}

	changeForm(form) {
		switch (form) {
		case 1:
			$(".modal-login").addClass("display");
			$(".modal-forgot").removeClass("display");
			break;
		case 2:
			this.forgotPassword();
			break;
		case 3:
			$(".modal-verify").addClass("display");
			$(".modal-login").removeClass("display");
			this.user.username.error = false;
			this.user.password.error = false;
			this.errorMessage = "";
			break;
		case 4:
			this.forgotPasswordConfirmation();
			break;
		default:
		}
	}
}

module.exports = signinController;
