'use strict';

class crivoHelper {
	// @ngInject
	constructor(visualForceApi) {
		this.visualForceApi = visualForceApi;
		this.remoteCallErrorMessage =
			'Serviço do Crivo indisponível no momento tente novamente mais tarde! Para continuar o preenchimento, favor fechar esta mensagem e atentar-se para a inclusão dos dados conforme o documento do cliente.';
		this.setPristine();
	}

	setPristine() {
		this.setData({
			isSuccessful: false,
			message: '',
			req_cpfCnpj: '',
			req_dataNascimento: '',
			req_inscricaoEstadual: '',
			req_inscricaoMunicipal: '',
			req_nome: '',
			req_nomeFantasia: '',
			req_razaoSocial: '',
			req_sexo: '',
			req_tipo: ''
		});
	}

	setData(data) {
		this.data = data;
	}

	getData() {
		return this.data;
	}

	consultarCrivo(cpfCnpj, gtoken, callbackfunc) {
		const me = this;

		me.crivo(cpfCnpj, gtoken).then((response) => {
			console.log('RETORNO DO CRIVO: ', response);
			me.setData(response);
			callbackfunc.call(this, me.getData());
		});
	}

	crivo(cpfcnpj, gtoken) {
		const request = { cpfcnpj, gtoken };
		return this.visualForceApi.invoke('CapCommunityCrivoCtrlExt.consultarCrivo', request);
	}
}

module.exports = crivoHelper;
