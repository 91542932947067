'use strict';

const signinController = require('./signin-controller');
const signinService = require('./signin-service');

const homeModule = angular.module('cap.public.signinModule', [])
	.controller('signinController', signinController)
	.service('signinService', signinService);

module.exports = homeModule;
