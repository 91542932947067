'use strict'

class PercentFieldDirective {
	// @ngInject
	constructor() {
		this.restrict = 'A';
		this.require = 'ngModel';
	}

	link(scope, element, attrs, ngModelController) {

		ngModelController.$parsers.push(
			(modelValue) => {

				let percent = parseFloat(modelValue.replace(',', '.'));

				if (!isNaN(percent)) {
					if (percent > 100.0) {
						percent = 100;
					}
					ngModelController.$setViewValue(percent.toString());
					ngModelController.$render();
				} else {
					percent = '';
					ngModelController.$setViewValue(percent);
					ngModelController.$render();
				}

				return percent;
			}
		)
	}

}

module.exports = PercentFieldDirective;
