'use strict';

class utilsHelper {

	// @ngInject	
	constructor() {
		this.title = 'utilsHelper';
	}

	getRepeatedElements(arrList) {

		const uniq = arrList.map((valor) => {
			const retval = { value: valor, count: 1 };
			return retval;
		}).reduce((a, b) => {
			a[b.value] = (a[b.value] || 0) + b.count;
			return a;
		}, {});

		const duplicates = Object.keys(uniq).filter((a) => uniq[a] > 1);

		return duplicates;
	}

	isElementChecked(arrList) {
		let retVal = false;
		if (!!arrList) {
			const selected = arrList.map((valor) => {
				const retval = { value: valor.selected, count: 1 };
				return retval;
			}).reduce((a, b) => {
				a[b.value] = (a[b.value] || 0) + b.count;
				return a;
			}, {});

			retVal = (selected["true"] > 0);
		}
		return retVal;
	}

	removeFormatacaoCpfCnpj(valor) {
		valor = this.replaceAll(valor, '.', '');
		valor = this.replaceAll(valor, '-', '');
		valor = this.replaceAll(valor, '/', '');
		return valor;
	}

	replaceAll(str, de, para) {
		//let str = this;
		let pos = str.indexOf(de);
		while (pos > -1) {
			str = str.replace(de, para);
			pos = str.indexOf(de);
		}
		return (str);
	}
}

module.exports = utilsHelper;
