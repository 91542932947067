'use strict';

class changePasswordController {

	// @ngInject
	constructor($scope, $window, validationFormFields, changePasswordApi, feedbackMessages, sfConfig) {

		this.newPassword = '';
		this.confirmPassword = '';
		this.$scope = $scope;
		this.$window = $window;
		this.validationFormFields = validationFormFields;
		this.changePasswordApi = changePasswordApi;
		this.message = '';
		this.logoUrl = `${sfConfig.resource}/images/logo_icatu.png`;
		this.username = `${sfConfig.username}`;

		this.setUserForm();
		this.setPasswordValidation();
	}

	setUserForm() {

		this.usersNewPassword = {

			userId: null, //ID do usuário
			password: null,
			passwordValid: false,
			confirmPassword: null,
			confirmPasswordValid: false,
			confirmPasswordError: false
		};

	}

	setPasswordValidation() {

		this.validation = {

			length: false,
			letter: false,
			number: false,
			symbol: false
		};

	}

	validateNewPassword(password) {

		//Validate password characters
		this.validationFormFields.validateLenghtPassword(password.$viewValue) ? this.validation.length = true : this.validation.length = false;
		this.validationFormFields.hasLetterPassword(password.$viewValue) ? this.validation.letter = true : this.validation.letter = false;
		this.validationFormFields.hasNumberPassword(password.$viewValue) ? this.validation.number = true : this.validation.number = false;
		this.validationFormFields.hasSymbolPassword(password.$viewValue) ? this.validation.symbol = true : this.validation.symbol = false;

		//Set the valid password
		if (this.validation.length && this.validation.letter && this.validation.number && this.validation.symbol) {

			this.usersNewPassword.passwordValid = true;
		} else {

			this.usersNewPassword.passwordValid = false;
		}

		console.log('usersNewPassword: ', this.usersNewPassword);
		console.log('Validation Object:', this.validation);
	}

	validateEqualPassword(confirmPassword) {
		//Validate the confirmed password equalty
		if (this.usersNewPassword.confirmPassword == this.usersNewPassword.password) {

			this.usersNewPassword.confirmPasswordValid = true;
		} else {

			this.usersNewPassword.confirmPasswordValid = false;
		}

		//Validate the confirmed password lenght for input feedback
		if ((this.usersNewPassword.password.length != this.usersNewPassword.confirmPassword.length) || !this.usersNewPassword.confirmPasswordValid) {

			this.usersNewPassword.confirmPasswordError = true;
		} else {

			this.usersNewPassword.confirmPasswordError = false;
		}
		console.log('usersNewPassword: ', this.usersNewPassword);
	}

	submitNewPassword(form) {
		form.$setSubmitted();

		if (form.$invalid) {

			this.$window.scrollTo(0, 0);

			this.message = 'É necessário preencher os campos de senha e confirmação de senha.';

			return;
		}

		this.message = '';

		this.changePasswordApi.sendNewPassword({ password: this.usersNewPassword.password, verifyPassword: this.usersNewPassword.confirmPassword }).then((response) => {
			console.log(response);
			if (response.isSuccessful) {
				this.message = '';
				this.$window.location.replace(response.url);
			} else {
				this.message = response.errorMessages[0];
				return false;
			}
		});

	}

}

module.exports = changePasswordController;
