"use strict";

import VisualForceApi from "../sfdc/visual-force-api.service.js";

class simulatorApi extends VisualForceApi {
	/* @ngInject */
	constructor($q) {
		super();
		this.$q = $q;
	}

	sendDataToSimulate(form) {
		return super.invoke(
			"CapCommunitySimuladorControllerExt.getSimulacao",
			form
		);
	}

	sendSimulationToEmail(form) {
		return super.invoke(
			"CapCommunitySimuladorControllerExt.solEnvioSimulacao",
			form
		);
	}

	getSimulationStatus() {
		return super.invoke(
			"CapCommunitySimuladorControllerExt.getConfigSimuladorAtiva",
			""
		);
	}

	executeSimulator(valorTotalProposta) {
		const deferred = this.$q.defer();

		console.log("valorTotalProposta: ", valorTotalProposta);

		const request = {
			valorTotalProposta
		};

		console.log("request parcelas: ", request);

		super
			.invoke("CapCommunitySimuladorControllerExt.simulador", request)
			.then((response) => {
				console.log("resultado busca parcela (service): ", response);
				deferred.resolve(response);
			});

		return deferred.promise;
	}

	getParcelasDG(valorTitulo, valorSeguro) {
		const deferred = this.$q.defer();

		console.log("valorTitulo: ", valorTitulo);

		const request = {
			valorTitulo,
			valorSeguro,
			tipoSimulador: 'PA'
		};

		console.log(request);

		super
			.invoke("CapCommunitySimuladorControllerExt.getParcelasDG", request)
			.then((response) => {
				console.log("resultado busca parcela (service): ", response);
				deferred.resolve(response);
			});

		return deferred.promise;
	}

	executeSimulatorDuplaGarantia(
		cpf,
		dataNascimento,
		mesesVigencia,
		valorTotalProposta
	) {
		const deferred = this.$q.defer();

		const request = {
			cpf,
			dataNascimento: this.formatDate(dataNascimento),
			mesesVigencia,
			valorTotalProposta
		};

		console.log("request executeSimulatorDuplaGarantia: ", request);

		return super
			.invoke(
				"CapCommunitySimuladorControllerExt.simuladorDuplaGarantia",
				request
			);
	}

	exportarPDF(tipoSimulador, form) {
		const deferred = this.$q.defer();

		const request = {
			tipoSimulador,
			nome: form.nome,
			valorAluguel: form.valor,
			valorTitulo: form.valor * form.multiplicador,
			multiplicador: form.multiplicador,
			parcelas: form.resultado,
			parcelasDG: form.parcelasDG,
			cpf: form.cpf,
			dataNascimento: form.dataNascimento,
			valorSeguroPrestamista: form.valorSeguroPrestamista,
			coberturaMorte: form.cobertura_morte_calculada,
			coberturaInvalidez: form.cobertura_invalidez_calculada,
			coberturaPerdaEmprego: form.cobertura_perda_emprego_calculada
		};

		console.log("request: ", request);

		super
			.invoke("CapCommunitySimuladorControllerExt.exportarPDF", request)
			.then((response) => {
				console.log("resultado exportar pdf (service): ", response);
				deferred.resolve(response);
			});

		return deferred.promise;
	}

	formatDate(inputDate) {
		const parts = inputDate.split("/");
		if (parts.length === 3) {
			const day = parts[0];
			const month = parts[1];
			const year = parts[2];

			// Certifique-se de que as partes da data sejam numéricas
			if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
				// Crie uma nova data no formato "yyyy-mm-dd"
				const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
          2,
          "0"
        )}`;
				return formattedDate;
			}
		}

		// Se o formato de entrada não for válido, retorne null ou outra marcação de erro
		return null;
	}
}

module.exports = simulatorApi;
