'use strict';

const routesConfiguration = require('./change-password.routes');

const changePasswordController = require('./change-password.controller');
const changePasswordApi = require('./change-password-api.service');

const changePasswordModule = angular.module('cap.secured.change-password', [
		'ui.router'
	])
	.controller('changePasswordController', changePasswordController)
	.service('changePasswordApi', changePasswordApi)

	.config(routesConfiguration);

module.exports = changePasswordModule;
