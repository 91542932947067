'use strict';

class cepHelper {

	// @ngInject
	constructor(visualForceApi) {
		this.visualForceApi = visualForceApi;
		this.setPristine();
		this.byPassDisableFields = false;
		this.remoteCallError = 0;
		this.remoteCallErrorMessage = 'Serviço indisponível. Favor completar os campos manualmente.';
	}

	setPristine() {
		this.setData({
			logradouro: true,
			bairro: true,
			cidade: true,
			uf: true
		});
	}

	setData(data) {
		this.data = data;
	}

	getData() {
		return this.data;
	}

	getAddressFromCEP(cep, callbackfunc) {
		const me = this;

		if (this.validateCEP(cep)) {
			console.log('Entrei no cepHelper.getAddressFromCEP', cep);

			// Após validar o cep
			// Chama serviço que retorna informaçoes do CEP (broker)
			this.getCepData(cep).then((response) => {
				console.log('Retorno do serviço  de consulta de Cep', response);
				me.setData(response);

				if (!response.isSuccessful) {
					me.remoteCallError = me.remoteCallError + 1;
				} else {
					me.remoteCallError = 0;
				}

				callbackfunc.call(this, me.getData());
			});
		}
	}

	validateCEP(cep) {
		let validacao = false;
		// Se a informação tiver 9 posições e um traço na posição 5
		if ((cep.length == 9) && (cep.indexOf("-") == 5)) {
			const cepPart = cep.split("-");
			if (!isNaN(cepPart[0]) && !isNaN(cepPart[1])) { // Verifica se são numéricas as partes
				validacao = true;
			}
			console.log(`cep ${cep} ${validacao ? 'válido' : 'inválido'}`);
		} else {
			this.setPristine();
		}

		return validacao;
	}

	getCepData(cep) {
		const request = { cep };

		const cap_access_token = localStorage.getItem("cap_access_token");
		request.accessToken = cap_access_token;

		return this.visualForceApi.invoke(
			'CapCommunityAccountControllerExt.consultaCep', request);
	}

	isCepDisabled(campo) {
		return !!this.data[campo] && !this.remoteCallErrorLimitWasExceded();
	}

	remoteCallErrorLimitWasExceded() {
		const me = this;
		return me.remoteCallError >= 3;
	}
}

module.exports = cepHelper;
