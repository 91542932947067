'use strict';
const routesConfiguration = require('./app-public-routes');
const configModule = { name: 'cap.config' };
const homeModule = require('./public/home/home-module');
const mainModule = require('./public/main/main-module');
const signinModule = require('./public/signin/signin-module');
const changePasswordModule = require('./public/change-password/change-password.module');
const coreModule = require('./core/core.module');
const appRun = require('./app-run');
const app = angular.module('cap.public', [
		configModule.name,
		homeModule.name,
		signinModule.name,
		coreModule.name,
		mainModule.name,
		changePasswordModule.name
	])
	.config(routesConfiguration);

module.exports = app;
