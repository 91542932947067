'use strict';

const routesConfiguration = require('./simulator.routes');

const simulatorController = require('./simulator.controller');
const simulatorGarantiaAluguelController = require('./simulator-ga.controller');
const simulatorDuplaGarantiaController = require('./simulator-dg.controller');
const simulatorPrestamistaAvulsoController = require('./simulator-pa.controller');

const simulatorApi = require('./simulator-api.service');

const simulatorOptionalCharges = require('./simulator-optional-charges.service');

const simulatorModule = angular
	.module('cap.core.simulatorModule', ['ui.router'])
	.controller('simulatorController', simulatorController)
	.controller('simulatorGarantiaAluguelController', simulatorGarantiaAluguelController)
	.controller('simulatorDuplaGarantiaController', simulatorDuplaGarantiaController)
	.controller('simulatorPrestamistaAvulsoController', simulatorPrestamistaAvulsoController)
	.service('simulatorApi', simulatorApi)
	.service('simulatorOptionalCharges', simulatorOptionalCharges)
	.config(routesConfiguration);

module.exports = simulatorModule;
