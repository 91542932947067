'use strict';

const routesConfiguration = require('./home-routes');
const homeBannerApiService = require('./home-banner-api.service');
const homeController = require('./home-controller');

const homeModule = angular.module('cap.public.homeModule', ['ui.router'])
	.controller('homeController', homeController)
	.service('homeBannerApiService', homeBannerApiService)
	.config(routesConfiguration);

module.exports = homeModule;
