'use strict';

/* @ngInject */
function routesConfiguration($stateProvider, sfConfig) {
	$stateProvider.state('home', {
		url: '/home',
		views: {
			'content': {
				templateUrl: `${sfConfig.resource}/views/public/home/home.html`
			}
		}
	});
};

module.exports = routesConfiguration;
